<template>
<div class="login-wrap coontainer px-4b  mt-0 ">
    <div class="row">

        <div class="col-12 text-center mt-0">
            <img src="@/assets/img/logo.svg" class="mb-2" height="58px" alt="">
            <h3 class="title  pt-0 text-centerb">
                Login </h3>

        </div>
    </div>

    <div class="row">

        <div class="col-lg-12 text-centerb ml-auto mr-auto col-md-12 pt-4">
            <ul class="errors px-0 text-left" v-for="error in validationErrors" :key="error.message">
                <li class="d-block text-danger px-0" v-if="validationErrors[0]!={}" data-aos="fade-in">
                    <ph-warning class="align-middle" />
                    <span class="ml-2 fnt-14 ls-default">{{ error.message }}</span>
                </li>
            </ul>
            <a href="http://localhost:5050/auth/google" class="btn btn-secondary btn-block btn-lg d-none">
                <img class="ph-googleb align-middle mx-2" style="height:16px" src="@/assets/img/google.svg"> Login with Google
            </a>
            <br class="d-none">
            <div class="label">
                <label class="d-block text-left label-text form-label" for>Email Address</label>
            </div>

            <div class="input-groupb input-lgb form-group">

                <input v-model="email" :class="{ 'form-c-error': emailError != '' }" type="email" @focus="resetError" class="form-control form-c form-c-lg" />
                <small class="small-error text-danger form-text text-left" v-show="emailError != ''">
                    <i class="ph-warning align-middle"></i>
                    {{ emailError }}</small>
            </div>
            <div class="label">
                <label class="d-block text-left label-text form-label" for>Password</label>
            </div>
            <div class="form-group">
                <input v-model="password" :class="{ 'form-c-error': passwordError != '' }" @focus="resetError" type="password" class="form-control form-c form-c-lg" />
                <small class="small-error text-danger form-text text-left" v-show="passwordError != ''">
                    <i class="ph-warning  align-middle"></i>
                    {{ passwordError }}</small>
            </div>
            <div class="send-button mt-0">
                <router-link to="/auth/forgot-password" class="pull-rightb f-5 link" style="font-size:16px">Forgot Password?</router-link>
            </div>
            <div class="send-button mt-3">
                <button class="btn btn-primary btn-block btn-lg" @click.prevent="validate()" :disabled="loading">
                    Login
                    <span v-show="loading" class="loading pl-1 my-auto pull-right text-right">
                    </span>
                </button>
                <div class="col-12 text-centern px-0">
                    <p class="description text-muted f-3" style="font-size:14px">
                        New User? <router-link to="/auth/signup" class="pull-left f-5 link" style="font-size:16px">Create Account</router-link>

                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import {
    PhWarning,

} from "phosphor-vue";
const email_re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
    name: "authlogin",
    components: {
        PhWarning,
    },
    data() {
        return {
            email: null,
            password: null,
            emailError: "",
            passwordError: "",
            validationErrors: [],
            loading: false,

        };
    },

    methods: {
        resetError() {
            this.validationErrors = [];
            this.emailError = "";
            this.passwordError = "";
        },
        ...mapActions(["setUpUserAction"]),
        validate() {
            this.resetError();
            this.loading = true

            if (!this.email) {
                this.emailError = "E-mail cannot be empty";
                this.loading = false
            }
            if (email_re.test(this.email) != true && this.email) {
                this.emailError = "Invalid Email Address format";
                this.loading = false
            }
            if (!this.password) {
                this.passwordError = "Password cannot be empty";
                this.loading = false
            }
            if (/.{6,}/.test(this.password) != true && this.password) {
                this.passwordError = "Password should be at least 6 characters long";
                this.loading = false
            }
            if (
                this.validationErrors.length <= 0 &&
                this.emailError === "" &&
                this.passwordError === ""
            ) {
                this.signIn();
            }
        },
        signIn() {
            var baseURI = this.$baseurl + "/auth/login";
            console.log(baseURI)
            this.$http.post(baseURI, {
                "email": this.email,
                "password": this.password
            }).then(result => {

                if (result.data.token) {
                    this.resetError()
                    this.loading = false
                    this.setUpUserAction(result.data);

                } else {
                    this.validationErrors.push({
                        "message": result.data.message
                    })

                    this.loading = false
                }

            });
        },
        onSignInSuccess(googleUser) {
            // `googleUser` is the GoogleUser object that represents the just-signed-in user.
            // See https://developers.google.com/identity/sign-in/web/reference#users
            const profile = googleUser.getBasicProfile() // etc etc
        },
        onSignInError(error) {
            // `error` contains any error occurred.
            console.log('OH NOES', error)
        },
        testv() {
            console.log("sign in", this.email, this.password);
        },
        OnGoogleAuthSuccess(idToken) {
            console.log(idToken)
            var decoded = jwt_decode(idToken);

            var baseURI = this.$baseurl + "/google_auth/";

            this.$http.post(baseURI, decoded).then(result => {
                if (result.data.token) {

                }

            });
            // Receive the idToken and make your magic with the backend
        },
        OnGoogleAuthFail(error) {
            console.log(error)
        }

    },

}
</script>

<style>

</style>
